<template>
  <b-container class="agencyrulemanagement">
    <b-row>
      <b-col cols="12" md="12" lg="10" offset-lg="1">
        <h2>Agency Assignment Rules</h2>
        <b-button variant="primary" class="mx-1 my-1" @click="showAddRule">Create New Rule</b-button>
        <b-button variant="primary" class="mx-1 my-1" @click="showImportCSV">Import Rules from CSV</b-button>
        <b-button variant="primary" class="mx-1 my-1" @click="refreshRules">Refresh Rules</b-button>
        <b-button variant="primary" class="mx-1 my-1" @click="showTestAssignment">Test Assignment</b-button>
        <b-button variant="warning" class="mx-1 my-1" @click="showDisableAll">Disable ALL rules</b-button>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group id="filter-checkbox-group" v-model="selectedFilters" :options="ruleFilterOptions"
            :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group>
        </b-form-group>
        <b-table striped small hover :fields="agencyRulesFields" :items="agencyRulesList" :sort-by.sync="rulesSortBy"
          :tbody-tr-class="rowClass">
          <template #cell(actions)="data">
            <b-button class="mx-2" size="sm" @click="showDisableRule(data.item.id)" variant="outline-danger"
              v-if="data.item.is_active">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal ref="newRuleModal" title="Create new rule" hide-header-close @ok="addRule" :ok-disabled="!newRuleFormValid">
      <b-form-group label="Brand:" label-for="newRuleBrand" :state="newBrandState"
        :invalid-feedback="newBrandInvalidFeedback">
        <b-form-input id="newRuleBrand" v-model="newRuleBrand"></b-form-input>
      </b-form-group>
      <b-form-group label="Price category:" label-for="newRulePriceCategory">
        <b-form-input id="newRulePriceCategory" v-model="newRulePriceCategory"></b-form-input>
      </b-form-group>
      <b-form-group label="3D Agency:" label-for="newRuleAgency" :state="newAgencyState"
        :invalid-feedback="newAgencyInvalidFeedback">
        <b-form-input id="newRuleAgency" v-model="newRuleAgency"></b-form-input>
      </b-form-group>
      <b-form-group label="Start date:" label-for="newRuleStartDate" description="Start date of the rule (inclusive)."
        :state="newStartDateState" :invalid-feedback="newStartDateInvalidFeedback">
        <b-form-datepicker id="newRuleStartDate" v-model="newRuleStartDate" today-button reset-button
          close-button></b-form-datepicker>
      </b-form-group>
      <b-form-group label="End date:" label-for="newRuleEndDate"
        description="Optional. End date of the rule (exclusive).">
        <b-form-datepicker id="newRuleEndDate" v-model="newRuleEndDate" today-button reset-button
          close-button></b-form-datepicker>
      </b-form-group>
      <b-button class="mx-1 my-1" @click="validateNewRule" variant="primary"
        :disabled="!newRuleFormValid || newRuleValidationLoading">
        Validate
        <b-icon v-if="newRuleValidationLoading" icon="arrow-clockwise" animation="spin"></b-icon>
      </b-button>
      <ul>
        <li v-for="line in newRuleValidationMessages" :key="line">
          {{ line }}
        </li>
      </ul>
    </b-modal>
    <b-modal ref="importRulesCSVModal" title="Import rules" hide-header-close ok-only ok-title="Close"
      ok-variant="secondary">
      <b-file id="csvFileDrop" v-model="importRulesCSVFile" :state="importRulesCSVFormValid" class="big-file-input"
        placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-file>
      <b-button class="mx-1 my-1" @click="validateCSVFile" variant="info" :disabled="!importRulesCSVFormValid">Validate
        file</b-button>
      <b-button class="mx-1 my-1" @click="submitCSVFile" variant="primary" :disabled="!importRulesCSVFormValid">Submit
        file</b-button>
    </b-modal>
    <b-modal ref="csvValidationModal" title="Validating CSV" hide-footer no-close-on-backdrop no-close-on-esc
      hide-header-close>
      <b-list-group>
        <b-list-group-item v-for="stage in validationInfo.stages" :key="stage.index"
          class="flex-column justify-content-between">
          <b-icon v-if="stage.state == STAGE_STATES.WAITING" icon="arrow-clockwise"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.IN_PROGRESS" icon="arrow-clockwise" variant="primary"
            animation="spin"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.FINISHED" icon="check-circle" variant="success"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.ERROR" icon="x-circle" variant="danger"></b-icon>
          {{ stage.display_text }}
          <b-progress v-if="stage.progressTotal" :value="stage.progress" :max="stage.progressTotal" show-progress>
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="validationInfo.finished">
        <h3>Result</h3>
        <p>{{ validationInfo.resultText }}</p>
        <ul>
          <li v-for="line in validationInfo.resultLines" :key="line">
            {{ line }}
          </li>
        </ul>
      </div>
      <b-button block @click="closeValidationModal" :disabled="!validationInfo.finished">Close</b-button>
    </b-modal>
    <b-modal ref="csvSubmitModal" title="Ingesting CSV" hide-footer no-close-on-backdrop no-close-on-esc
      hide-header-close>
      <b-list-group>
        <b-list-group-item v-for="stage in submitInfo.stages" :key="stage.index"
          class="flex-column justify-content-between">
          <b-icon v-if="stage.state == STAGE_STATES.WAITING" icon="arrow-clockwise"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.IN_PROGRESS" icon="arrow-clockwise" variant="primary"
            animation="spin"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.FINISHED" icon="check-circle" variant="success"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.ERROR" icon="x-circle" variant="danger"></b-icon>
          {{ stage.display_text }}
          <b-progress v-if="stage.progressTotal" :value="stage.progress" :max="stage.progressTotal" show-progress>
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="submitInfo.finished">
        <h3>Result</h3>
        <p>{{ submitInfo.resultText }}</p>
        <ul>
          <li v-for="line in submitInfo.resultLines" :key="line">{{ line }}</li>
        </ul>
      </div>
      <b-button block @click="closeSubmitModal" :disabled="!submitInfo.finished">Close</b-button>
    </b-modal>
    <b-modal ref="testAssignmentModal" title="Test Assignment" ok-only ok-title="Close" ok-variant="secondary">
      <b-form-group label="Brand:" label-for="testRuleBrand">
        <b-form-input id="testRuleBrand" v-model="testRuleBrand"></b-form-input>
      </b-form-group>
      <b-form-group label="Price category:" label-for="testRulePriceCategory">
        <b-form-input id="testRulePriceCategory" v-model="testRulePriceCategory"></b-form-input>
      </b-form-group>
      <b-form-group label="Date:" label-for="testRuleDate" description="Date to test from.">
        <b-form-datepicker id="testRuleDate" v-model="testRuleDate" today-button reset-button
          close-button></b-form-datepicker>
      </b-form-group>
      <b-button variant="primary" @click="submitTestAssignment">Submit Test</b-button>
      <!-- TODO: Reset values button useful or no? -->
      <!-- <b-button variant="secondary" @click="resetTestAssignment"
        >Reset values</b-button
      > -->
      <div v-if="testRuleResult">
        <h3>Result</h3>
        <p>{{ testRuleResult }}</p>
        <!-- <ul>
          <li v-for="line in validationInfo.resultLines" :key="line">{{line}}</li>
        </ul> -->
      </div>
    </b-modal>
    <b-modal ref="disableRuleModal" title="Disable rule?" hide-header-close @ok="disableRule">
      <template #modal-ok>Confirm</template>
      <label>Rule ID:</label>
      <b-form-input v-model="disableRuleId" disabled></b-form-input>
    </b-modal>
    <b-modal ref="disableAllRulesModal" title="Disable ALL rules?" hide-header-close @ok="disableAllRules">
      Rules cannot be enabled after disabling them! You can import the same rules from a CSV import.
      <template #modal-ok>Confirm</template>
    </b-modal>
  </b-container>
</template>
<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig, formatDateAsStr } from "@/util";

const _STAGE_STATES = {
  WAITING: 10,
  IN_PROGRESS: 20,
  FINISHED: 30,
  ERROR: -100,
};

const _resetValidationInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100,
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server validation",
      },
    },
  };
};

const _resetSubmitInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100,
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server processing",
      },
    },
  };
};

export default {
  name: "AgencyRulesManagement",
  data: function () {
    return {
      STAGE_STATES: _STAGE_STATES,
      selectedFilters: ["includeFuture"],
      ruleFilterOptions: [
        { text: "Include expired", value: "includeExpired" },
        { text: "Include future", value: "includeFuture" },
        { text: "Include disabled (Can be a lot!)", value: "includeDisabled" },
      ],
      newRuleBrand: "",
      newRulePriceCategory: "",
      newRuleAgency: "",
      newRuleStartDate: this.getTodayStr(),
      newRuleEndDate: null,
      newRuleValidationMessages: [],
      newRuleValidationLoading: false,
      importRulesCSVFile: null,
      validationInfo: _resetValidationInfo(),
      submitInfo: _resetSubmitInfo(),
      testRuleBrand: "",
      testRulePriceCategory: "",
      testRuleDate: this.getTodayStr(),
      testRuleResult: "",
      disableRuleId: null,
      agencyRulesFields: [
        "id",
        { key: "brand", sortable: true },
        { key: "price_category", sortable: true },
        { key: "agency_name", sortable: true },
        { key: "start_date", sortable: true },
        { key: "end_date", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      rulesSortBy: "brand",
    };
  },
  computed: {
    agencyRulesList() {
      return this.$store.state.agencyRulesList
    },
    newBrandState() {
      return this.newRuleBrand.length > 0;
    },
    newBrandInvalidFeedback() {
      return "Required";
    },
    newAgencyState() {
      return this.newRuleAgency.length > 0;
    },
    newAgencyInvalidFeedback() {
      return "Required";
    },
    newStartDateState() {
      return this.newRuleStartDate ? true : false;
    },
    newStartDateInvalidFeedback() {
      return "Required";
    },
    newRuleFormValid: function () {
      return (
        this.newBrandState && this.newAgencyState && this.newStartDateState
      );
    },
    fileIsValid() {
      if (this.importRulesCSVFile == null) {
        return null;
      }
      return this._validateFile(this.importRulesCSVFile);
    },
    importRulesCSVFormValid: function () {
      return this.fileIsValid;
    },
  },
  methods: {
    rowClass: function (ruleObj, type) {
      if (!ruleObj || type !== 'row') { return }  // Skip
      if (!ruleObj.is_active) return 'table-secondary'
    },
    // TODO: Move to common utility functions?
    getTodayStr: function () {
      /** Returns today in YYYY-MM-DD format. */
      let today = new Date();
      return formatDateAsStr(today)
    },
    showAddRule: function () {
      this.newRuleBrand = "";
      this.newRulePriceCategory = "";
      this.newRuleAgency = "";
      this.newRuleStartDate = this.getTodayStr();
      this.newRuleEndDate = null;
      this.newRuleValidationMessages = []
      this.newRuleValidationLoading = false
      this.$refs["newRuleModal"].show();
    },
    addRule: function () {
      let payload = {
        brand: this.newRuleBrand,
        price_category: this.newRulePriceCategory,
        agency_name: this.newRuleAgency,
        start_date: this.newRuleStartDate,
        end_date: this.newRuleEndDate
      };
      this.$store
        .dispatch("createAgencyRule", payload)
        .then((res) => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "New rule created.";
          toastConfig.variant = "success";
          this.$bvToast.toast(`Created rule ID: ${res.id}`, toastConfig);
          this.refreshRules();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    async validateNewRule() {
      this.newRuleValidationLoading = true
      this.newRuleValidationMessages = []
      let payload = {
        brand: this.newRuleBrand,
        price_category: this.newRulePriceCategory,
        agency_name: this.newRuleAgency,
        start_date: this.newRuleStartDate,
        end_date: this.newRuleEndDate
      };
      const res = await api.postValidateAgencyRule(localStorage.token, payload)
      if (res.messages.length > 0) {
        this.newRuleValidationMessages = res.messages
      } else {
        this.newRuleValidationMessages.push('All values are valid and known!')
      }
      this.newRuleValidationLoading = false
    },
    showImportCSV: function () {
      this.importRulesCSV = null;
      this.$refs["importRulesCSVModal"].show();
    },
    _validateFile: function (_file) {
      if (!_file) {
        return false;
      }
      let fparts = _file.name.split(".");
      if (fparts.length <= 1 || fparts[fparts.length - 1] !== "csv") {
        return false;
      }
      return true;
    },
    _checkFiles() {
      if (!this.importRulesCSVFormValid) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "No file set!";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `Please browse or drop a file on the file box.`,
          toastConfig
        );
        return false;
      }
      return true;
    },
    async validateCSVFile() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.validationInfo = _resetValidationInfo();
      this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["csvValidationModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      // let file = this.files[0];
      let _file = this.importRulesCSVFile;
      // TODO: Error handling missing file here?
      fileData.append("csv", _file, _file.name);
      let requestConfig = {
        onUploadProgress: (progEvt) => {
          this.validationInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.validationInfo.stages.UPLOAD.progress >= 99.999999) {
            this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        },
      };
      try {
        let res = await api.postValidateRulesCSV(
          localStorage.token,
          fileData,
          requestConfig
        );
        //this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
        this._setValidationResult(res);
        this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
        this.validationInfo.finished = true;
      } catch (err) {
        console.log(err);
        this.validationInfo.finished = true;
        this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.$store.commit("setLeaveGuard", false);
      }
    },
    _setValidationResult(respData) {
      this.validationInfo.resultText = `Import contains ${respData.new_rules.length} new rules.`;
      if (respData.unknown_brands) {
        this.validationInfo.resultLines.push(`Found ${respData.unknown_brands.length} unknown brands: ${respData.unknown_brands}`)
      }
      if (respData.unknown_price_categories) {
        this.validationInfo.resultLines.push(`Found ${respData.unknown_price_categories.length} unknown price categories: ${respData.unknown_price_categories}`)
      }
      if (respData.unknown_agencies) {
        this.validationInfo.resultLines.push(`Found ${respData.unknown_agencies.length} unknown agencies: ${respData.unknown_agencies}`)
      }
    },
    closeValidationModal() {
      this.$refs["csvValidationModal"].hide();
    },
    async submitCSVFile() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["csvSubmitModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      // let file = this.files[0];
      let _file = this.importRulesCSVFile;
      // TODO: Error handling missing file here?
      fileData.append("csv", _file, _file.name);
      let requestConfig = {
        onUploadProgress: progEvt => {
          this.submitInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        }
      };
      try {
        let res = await api.postSubmitRulesCSV(
          localStorage.token,
          fileData,
          requestConfig
        );
        this._setSubmitResult(res);
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
        this.refreshRules()
      } catch (err) {
        console.log(err);
        this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.ERROR;
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;
        this.$store.commit("setLeaveGuard", false);
      }
    },
    _setSubmitResult(respData) {
      this.submitInfo.resultText = `Imported ${respData.new_rules.length} new rules.`;
      if (respData.unknown_brands) {
        this.submitInfo.resultLines.push(`Found ${respData.unknown_brands.length} unknown brands: ${respData.unknown_brands}`)
      }
      if (respData.unknown_price_categories) {
        this.submitInfo.resultLines.push(`Found ${respData.unknown_price_categories.length} unknown price categories: ${respData.unknown_price_categories}`)
      }
      if (respData.unknown_agencies) {
        this.submitInfo.resultLines.push(`Found ${respData.unknown_agencies.length} unknown agencies: ${respData.unknown_agencies}`)
      }
    },
    closeSubmitModal() {
      this.$refs["csvSubmitModal"].hide();
    },
    showTestAssignment: function () {
      this.testRuleResult = "";
      this.$refs["testAssignmentModal"].show();
    },
    async submitTestAssignment() {
      let payload = {
        brand: this.testRuleBrand,
        price_category: this.testRulePriceCategory,
        process_date: this.testRuleDate
      }
      try {
        const agencyRule = await api.postTestAgencyRule(localStorage.token, payload)
        this.testRuleResult = `Assignment to agency "${agencyRule.agency_name}". (Rule #${agencyRule.id})`;
      } catch (err) {
        if (err.response.status == 404) {
          this.testRuleResult = `No matching rule found.`;
          return
        }
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
    resetTestAssignment: function () {
      this.testRuleBrand = "";
      this.testRulePriceCategory = "";
      this.testRuleDate = this.getTodayStr();
      this.testRuleResult = "";
    },
    refreshRules: function () {
      // TODO: Set loading state to true
      let includeFuture = this.selectedFilters.includes('includeFuture')
      let includeExpired = this.selectedFilters.includes('includeExpired')
      let includeDisabled = this.selectedFilters.includes('includeDisabled')
      let payload = {
        includeFuture: includeFuture ? 1 : '',
        includeExpired: includeExpired ? 1 : '',
        includeDisabled: includeDisabled ? 1 : '',
      }
      this.$store
        .dispatch("getAgencyRules", payload)
        .then(() => {
          // TODO: Set loading state to false
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showDisableRule: function (ruleId) {
      this.disableRuleId = ruleId;
      this.$refs["disableRuleModal"].show();
    },
    disableRule: function () {
      this.$store
        .dispatch("disableAgencyRule", this.disableRuleId)
        .then(() => {
          this.refreshRules();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showDisableAll: function () {
      this.$refs["disableAllRulesModal"].show();
    },
    disableAllRules: function () {
      this.$store
        .dispatch("disableAllAgencyRules")
        .then(() => {
          this.refreshRules();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    }
  },
  components: {},
  created() { },
  mounted() {
    if (this.$store.state.agencyRulesList.length === 0) {
      this.refreshRules();
    }
  },
};
</script>